<template>
  <section class="data-list-container">
    <v-card>
      <v-card-title>{{title}}</v-card-title>
      <v-card-text>
        <v-list dense>
          <v-subheader>Client Distributie</v-subheader>
          <v-list-item-group>
            <v-list-item v-for="(info, key) of customer">
              <v-list-item-content>
                <template v-if="key === 'details'">
                  <v-subheader>Detalii</v-subheader>
                  <v-list-item v-for="(info1, key1) of info">

                    <v-list-item-title v-text="key1"></v-list-item-title>
                  <v-list-item-subtitle v-text="info1"></v-list-item-subtitle>
                  </v-list-item>
                </template>
                <template v-else>
                  <v-list-item-title v-text="key"></v-list-item-title>
                  <v-list-item-subtitle v-text="info"></v-list-item-subtitle>
                </template>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </section>
</template>

<script>
export default {
  name: 'OldCitResellersShow',
  data() {
    return {
      customer:{
        details:{}
      }
    }
  },
  computed:{
    title(){
      if(this.customer){
        return this.customer.name
      }else{
        return ''
      }
    }
  },
  methods: {
    loadItem(){
      this.$http.get(`old-customers/${this.$route.params.id}`)
      .then(({data}) => {
        this.customer = data
      })
    }
  },
  created() {
    this.loadItem()
  }
}
</script>

<style scoped lang="scss">
.v-list-item {
  @apply py-2;
  &:nth-child(odd) {
    @apply bg-grey-light rounded;
  }
  &__title {
    @apply mb-3;
  }
  &__subtitle {
    @apply text-base;
  }
}
</style>
